// Slovenian
export default{
    form: {
        startSurveyButton: "Začnimo",
        nextButton: "Naprej",
        submitAnswerButton: "Pošlji odgovor",
        continueButton: "Nadaljuj",
        finishButton: "Dokončaj",
        headerPercentageCompletion: 'dokončano',
        headerQuestionsCompleted: 'vprašanja',
        headerDefaultErrorMessage: 'Ups! Nekaj je šlo narobe.',
        brandingPoweredBy: 'Poganja',
        brandingPromoWhySurvey: 'Zakaj anketa, če lahko uporabite <b>{product}</b>?',
        brandingPromoGetStarted: 'Začnite še danes!',
        submissionPending: "Vaš voiceform se pošilja. Prosimo, ne zapirajte okna brskalnika.",
        submissionCanCloseWindow: "Vaš voiceform je bil uspešno poslan. Zdaj lahko zaprete okno 👍",
        mediaAttachmentOverlayCloseButton: "Zapri",
        expiredFormHeader: "Hvala za obisk!",
        expiredFormMessage: "Zdi se, da je ta anketa potekla. Prosimo, kontaktirajte ustvarjalca voiceforma in ga obvestite.",
        notPublishedFormHeader: "Voiceform ni objavljen!",
        notPublishedFormMessage: "Zdi se, da ta anketa ni bila objavljena. Prosimo, kontaktirajte ustvarjalca voiceforma in ga obvestite.",
        notPublishedFormRetryButton: "Poskusi znova",
        errorFormHeader: "Ups!",
        errorFormMessage: "Nekaj je šlo narobe. Prosimo, poskusite znova kasneje.",
        errorFormRetryButton: "Poskusi znova",
        emptyFormHeader: "Hvala za obisk!",
        emptyFormMessage: "Ups! Zdi se, da je ta obrazec trenutno prazen, brez vprašanj ali polj za izpolnitev. Opravičujemo se za nevšečnosti. Prosimo, kontaktirajte ustvarjalca tega obrazca in ga obvestite o težavi. Hvala za vaše razumevanje in sodelovanje!",

        restoreFormHeader: "Zdi se, da imate obrazec v teku!",
        restoreFormMessage: "Želite nadaljevati tam, kjer ste končali?",
        restoreFormButton: "Nadaljuj obrazec",
        restoreFormButtonNew: "Začni znova",

        audioPermissionHeader: "Želite odgovoriti z glasom?",
        audioPermissionMessage: "Govorjenje bo omogočilo hitro in enostavno odgovarjanje.",
        audioPermissionAcceptButton: "Da, želim",
        audioPermissionDenyButton: "Ne, hvala",
        audioPermissionErrorHeader: "Mikrofon ni na voljo",
        audioPermissionErrorMessage: "Prosimo, preverite dovoljenja brskalnika za omogočanje dostopa do mikrofona.",
        audioPermissionErrorButton: "V redu",
        audioRecorderFailedUpload: "Nalaganje posnetka ni uspelo",
        silenceDetectorError: "Ne slišimo vas. Preverite nastavitve mikrofona. Nasvet: Morda gre za zunanji mikrofon.",
        audioRecorderButtonRecord: "Začni snemanje",
        audioRecorderButtonRecordMore: "Posnemi več",
        voiceResponseSelectionMessage: "Izberite, kako želite odgovoriti...",
        voiceResponseSelectionOrDivider: "ali",
        voiceResponseTextInputPlaceholder: "Vnesite odgovor",
        voiceResponseTextInputCharacterCounterMinMessage: "Prosimo, vnesite vsaj {min} znakov",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} znakov (najmanj {min} znakov)",
        voiceResponseTextInputCharacterValidationMessage: "{count} znakov (najmanj {min} znakov)",

        // checkbox
        checkboxValidationTooFew: "Izbrati morate vsaj {min} možnost(i)",
        checkboxValidationTooMany: "Izbrati smete največ {max} možnost(i)",
        checkboxNoneOfTheAboveOption: "Nobena od zgoraj navedenih",
        checkboxInvalidAnswer: "Neveljaven odgovor.",

        // datepicker
        datePickerPlaceholder: "Izberite datum",

        // dropdown
        // email
        emailLabel: "E-naslov",
        emailInvalid: "Neveljaven e-naslov.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Snemaj video",
        fileUploadPluginScreenCast: "Zajem zaslona",
        fileUploadVideoImportFilesDefault: 'Posnemite ali naložite video prek:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Izberite možnost snemanja videa:',
        fileUploadVideoImportFilesNoCamera: 'Kliknite gumb za snemanje videa zaslona',
        fileUploadVideoImportFilesNoScreenCast: 'Kliknite gumb za snemanje videa',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Na voljo ni možnosti za snemanje videa',

        // matrix
        matrixValidationMessage: "Odgovor je veljaven.",
        matrixRow: "Vrstica",
        matrixColumn: "Stolpec",
        matrixRowRequired: "Vrstica {rowTitle} je obvezna.",
        matrixRadioGroup: "Radijska skupina",
        matrixCheckboxGroup: "Skupina potrditvenih polj",
        matrixGroupRequired: "Za vrstico {rowTitle}. {type} {groupTitle} je obvezen.",
        matrixColumnRequired: "Za vrstico {rowTitle}. Stolpec {columnTitle} je obvezen.",
        matrixColumnInvalid: "Za vrstico {rowTitle}. Stolpec {columnTitle} ni veljaven.",
        matrixRequired: "Obvezno.",
        matrixNumericMustBeNumber: "Mora biti številka.",
        matrixNumericMustBeGreaterThenMin: "Mora biti večje kot {min}.",
        matrixNumericMustBeLessThenMax: "Mora biti manjše kot {max}.",
        matrixNumericMustBeInteger: "Mora biti celo število.",
        matrixNumericInvalidNumber: "Neveljavna številka.",

        // name
        nameLabel: "Ime",
        nameInvalid: "Vnesite ime in priimek.",

        // nps
        npsNotLikely: "Zelo malo verjetno",
        npsExtremelyLikely: "Zelo verjetno",

        // numeric input
        numericInputRequired: "Obvezno.",
        numericInputMustBeNumber: "Mora biti številka.",
        numericInputMustBeGreaterThenMin: "Mora biti večje kot {min}.",
        numericInputMustBeLessThenMax: "Mora biti manjše kot {max}.",
        numericInputMustBeInteger: "Mora biti celo število.",
        numericInputInvalidNumber: "Neveljavna številka.",
        numericInputPlaceholder: "Vnesite številko",

        // phone
        phoneInvalid: "Telefonska številka je neveljavna.",
        phoneCountrySelectorLabel: 'Koda države',
        phoneCountrySelectorError: 'Izberite državo',
        phoneNumberLabel: 'Telefonska številka',
        phoneExample: 'Primer:',

        // boolean
        booleanYesLabel: "Da",
        booleanNoLabel: "Ne",

        //questionStep
        questionStepAudioQuestionLabel: "Zvočno vprašanje",

        // errors
        invalidPhoneNumber: "{phone} je neveljavna telefonska številka.",
        invalidEmail: "{email} je neveljaven e-naslov.",
        questionIsRequired: "Vprašanje je obvezno.",
        answerIsNotValid: "Odgovor ni veljaven.",
        unfinishedProbingDialogError: "Dokončajte pogovor.",
        cannotResumePartialResponse: "Ni mogoče nadaljevati delnega odgovora.",
        failedToSubmitForm: "Obrazca ni bilo mogoče poslati. Preverite internetno povezavo in poskusite znova."
    }
}
